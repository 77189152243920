<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.permissions')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.GeneralWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.permissions.add_permission') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="employeePermissionGroups" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>

        <AddEmployeePermissionGroup ref="addModal" v-on:itemAdded="loadData"/>
        <UsedAtModal ref="usedAtModal" @confirm="confirmUsedAt" @cancel="cancelUsedAt">
            <p v-if="employeesWithPermission && employeesWithPermission.length > 0">
                {{$t('keys.permissions.permission')}} <b>{{this.permissionName}}</b> {{$t('keys.permissions.permission_middle')}}
                (<b>{{employeesWithPermission.length}}</b>) {{$t('keys.permissions.permission_end')}}.
            </p>
            <p v-if="employeesWithPermission && employeesWithPermission.length > 0">
                {{$t('keys.permissions.employee_list')}}:
            </p>
            <div v-if="employeesWithPermission && employeesWithPermission.length > 0">
                <ul>
                    <li v-for="item in employeesWithPermission.slice(0,5)" :key="item.id">{{ item.name }}</li>
                    <li v-if="employeesWithPermission.length > 5">...</li>
                </ul>
            </div>
            <p v-if="employeesWithPermission && employeesWithPermission.length > 0">
                {{$t('keys.permissions.permission_must_delete')}}.
            </p>
            <p v-if="employeesWithPermission && employeesWithPermission.length === 0">
                {{$t('print.confirm.delete_row')}}
            </p>
        </UsedAtModal>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddEmployeePermissionGroup from '@/views/EmployeePermissionGroup/AddEmployeePermissionGroup'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import UsedAtModal from '@/views/components/UsedAtModal.vue'
    import {getPermissions} from '@/libs/constants/employees.js'


    export default {
        mixins: [itemChange],
        components: {UsedAtModal, Table, AddEmployeePermissionGroup, BCard, BOverlay, BButton},
        computed: {
            employeePermissions() {
                return getPermissions()
            },
            columnDefs() {
                const hasPermission = this.$hasPermission(this.$permissions.GeneralWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), field: 'name', editable: hasPermission, filter: true},
                    { headerName: this.$t('table_fields.permissions'), editable: hasPermission, field: 'permissions', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.employeePermissions, multiple: true, selectValue: 'value'} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.employeePermissions, multiple: true, selectValue: 'value', findValueInArray: true} }, cellRenderer: (params) => this.getPermissionName(params.value)}
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                employeePermissionGroups: [],
                showLoader: false,
                permissionName: '',
                selectedId: null,
                employeesWithPermission: []
            }
        },

        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/employee_permission_groups/')
                    this.employeePermissionGroups = response.data ?? []

                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async confirmUsedAt() {
                this.showLoader = true
                try {
                    await this.$http.delete(`/api/client/v1/employee_permission_groups/${this.selectedId}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    await this.loadData()
                    this.selectedId = null
                    this.showLoader = false
                }
            },
            cancelUsedAt() {
                this.selectedId = null
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/employee_permission_groups/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {

                try {
                    const response = await this.$http.get(`/api/client/v1/employees/check/${id}`)
                    this.employeesWithPermission = response.data.employees ?? []
                    this.permissionName = response.data.permission_group.name ?? ''

                    let okButton = false
                    if (this.employeesWithPermission.length > 0) {
                        okButton = true
                    }

                    this.selectedId = id
                    this.$refs.usedAtModal.show(okButton)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getPermissionName(value) {
                if (value && value.length > 0) return value.filter(opt => {
                    const item = this.employeePermissions.find(x => x.value === opt)
                    return !!item
                }).map(option => {
                    const item = this.employeePermissions.find(x => x.value === option)
                    if (item) return item.name
                }).join(', ')
                return '/'
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
